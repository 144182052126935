<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Notificações
                    </v-toolbar-title>
                    <v-spacer/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 200"
                            @click:row="onClickRow"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <template v-slot:item.notificacao.text="{ item }">
                                {{ truncateText(item.notificacao.text, 60) }}
                            </template>
                            <template v-slot:item.date="{ item }">
                                {{ timeAgo(item.created_at) }}
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-icon @click.stop="onClickRow(item.id)" color="primary">mdi-eye</v-icon>
                            </template>
                        </v-data-table>
                        <Paginate
                            v-model="pagination"
                            @input="consultar"
                        />
                    </v-card-text>
                </v-card>
            </v-content>

            <v-dialog v-model="visible" persistent max-width="500px">
                <v-card>
                    <v-card-title class="justify-space-between align-center" style="background-color: #f5f5f5;">
                        <span class="text-h6 font-weight-bold">{{ notificacao.title }}</span>
                        <v-btn icon @click="visible = false" color="grey darken-1">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    
                    <v-divider/>
                    
                    <v-card-text class="py-4 px-6 text-center">
                        <span style="font-size: 16px; line-height: 1.5; color: #666;">
                            {{ notificacao.text }}
                        </span>
                    </v-card-text>
                    
                    <v-divider/>
                    
                    <v-card-actions class="justify-end px-4 pb-4">
                        <v-btn text color="primary" @click="visible = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';

export default {
    name: 'Notificacoes',

    components: {
        BaseContainer,
        Paginate,
    },

    data: () => ({
        loading: true,
        dados: [],
        headers: [
            { text: 'Título', value: 'notificacao.title' },
            { text: 'Mensagem', value: 'notificacao.text' },
            { text: 'Data', value: 'date' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
        notificacao: {},
        visible: false,
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];

            const params = this.getParams();

            this.$http.post('notificacoes/get-by-user?' + params).then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        getParams() {
            const params = new URLSearchParams();

            params.append('perPage', 20);
            params.append('page', this.pagination.current_page);

            if (this.options.sortBy.length) {
                params.append('sortField', this.options.sortBy[0]);
            }
            if (this.options.sortDesc.length) {
                params.append('sortOrder', this.options.sortDesc[0] ? 1 : -1);
            }

            return params.toString();
        },

        onClickRow(row) {
            this.notificacao = row.notificacao;
            this.visible = true;
        },

        truncateText(text, maxLength) {
            return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
        },
    },
}
</script>
